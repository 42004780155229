<template>
  <div class="job-details__basic-details">
    <div
      class="d-block d-md-flex justify-space-between align-center px-4 px-md-0"
    >
      <div class="mb-2 mb-md-0">
        <v-icon class="mr-2 v-icon__color-light-gray-2">mdi-map-marker</v-icon>
        {{ localize(joboffer.location) }}
      </div>
      <div class="mb-2 mb-md-0">
        <v-icon class="mr-2 v-icon__color-light-gray-2">mdi-information</v-icon>
        {{ localize(joboffer.workingTime) }} /
        {{ joboffer.hoursPerWeek }} Stunden
      </div>
      <div class="mb-2 mb-md-0">
        <v-icon class="mr-2 v-icon__color-light-gray-2"
          >mdi-calendar-range</v-icon
        >
        {{ startDate }}
      </div>
      <div class="mb-2 mb-md-0">
        <v-btn
          :href="joboffer.applyUrl"
          target="_blank"
          class="d-block d-sm-inline-block"
          >{{ $t('jobs.applyNow') }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    }
  },

  computed: {
    startDate() {
      const startDate = new Date(this.joboffer.employmentStartDate);
      if (startDate < new Date()) {
        return 'Ab sofort';
      } else {
        return 'Ab ' + this.$d(startDate);
      }
    }
  }
};
</script>
