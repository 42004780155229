import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import TopFilterLayout from './layouts/top-filter-layout.vue';
import SideFilterLayout from './layouts/side-filter-layout.vue';
import NoFilterLayout from './layouts/no-filter-layout.vue';
import JobDetailsLayout from './layouts/job-details-layout.vue';
import HeroFilterOnlyLayout from './layouts/hero-filter-only-layout.vue';
import i18n from './i18n';

// register layout variants
Vue.component('no-filter-layout', NoFilterLayout);
Vue.component('top-filter-layout', TopFilterLayout);
Vue.component('side-filter-layout', SideFilterLayout);
Vue.component('job-details-layout', JobDetailsLayout);
Vue.component('hero-filter-only-layout', HeroFilterOnlyLayout);

// register global mixins
import { localizationMixin } from '@/mixins/localization.js';
Vue.mixin(localizationMixin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
