import { render, staticRenderFns } from "./inner-content-area.vue?vue&type=template&id=682058a1&"
import script from "./inner-content-area.vue?vue&type=script&lang=js&"
export * from "./inner-content-area.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports