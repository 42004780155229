export default {
  getContent(state) {
    return state.content;
  },

  getJoboffers(state) {
    if (!state.filters) {
      return state.joboffers;
    }

    let jobs = state.joboffers;
    for (const [field, keyFilter] of Object.entries(state.filters)) {
      if (keyFilter.length > 0) {
        if (field === 'keyword') {
          const keyword = keyFilter[0].value;
          jobs = jobs.filter((x) =>
            x.name.some(
              (n) =>
                n.translation.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
            )
          );
        } else {
          jobs = jobs.filter((x) =>
            keyFilter.some((kf) => kf.value === x[field])
          );
        }
      }
    }

    return jobs;
  },

  getCareerLevels(state) {
    const levels = [];
    for (const job of state.joboffers) {
      const matchingLevels = levels.filter((x) => x.id === job.careerLevelId);
      if (matchingLevels.length > 0) {
        matchingLevels[0].numJobs++;
      } else {
        levels.push({
          field: 'careerLevelId',
          id: job.careerLevelId,
          name: job.careerLevel,
          numJobs: 1
        });
      }
    }

    return levels;
  },

  getLocations(state) {
    const locations = [];
    for (const job of state.joboffers) {
      const matchingLocations = locations.filter(
        (x) => x.id === job.locationId
      );
      if (matchingLocations.length > 0) {
        matchingLocations[0].numJobs++;
      } else {
        locations.push({
          field: 'locationId',
          id: job.locationId,
          name: job.location,
          numJobs: 1
        });
      }
    }

    return locations;
  },

  getDepartments(state) {
    const departments = [];
    for (const job of state.joboffers) {
      if (job.departmentId <= 0) {
        continue;
      }

      const matchingDepartments = departments.filter(
        (x) => x.id === job.departmentId
      );

      if (matchingDepartments.length > 0) {
        matchingDepartments[0].numJobs++;
      } else {
        departments.push({
          field: 'departmentId',
          id: job.departmentId,
          name: job.department,
          numJobs: 1
        });
      }
    }

    return departments;
  },

  getWorkingTimes(state) {
    const workingTimes = [];
    for (const job of state.joboffers) {
      if (job.workingTimeId <= 0) {
        continue;
      }

      const matchingWorkingTimes = workingTimes.filter(
        (x) => x.id === job.workingTimeId
      );

      if (matchingWorkingTimes.length > 0) {
        matchingWorkingTimes[0].numJobs++;
      } else {
        workingTimes.push({
          field: 'workingTimeId',
          id: job.workingTimeId,
          name: job.workingTime,
          numJobs: 1
        });
      }
    }

    return workingTimes;
  },

  getCompanies(state) {
    const companies = [];
    for (const job of state.joboffers) {
      if (job.companyId <= 0) {
        continue;
      }

      const matchingCompanies = companies.filter((x) => x.id === job.companyId);

      if (matchingCompanies.length > 0) {
        matchingCompanies[0].numJobs++;
      } else {
        companies.push({
          field: 'companyId',
          id: job.companyId,
          name: job.companyName,
          numJobs: 1
        });
      }
    }

    return companies;
  }
};
