<template>
  <div class="job-details" v-if="joboffer">
    <basics :joboffer="joboffer"></basics>

    <div v-if="jobDetails" class="job-details__content-area">
      <content-area :content="jobDetails.content"></content-area>
    </div>

    <div class="content-box text-center mt-8">
      <h2>Sie wollen den Job?</h2>
      <p>
        Bewerben Sie sich direkt hier online oder nehmen SIe Kontakt mit unserem
        Team auf.
      </p>
      <div class="mt-8">
        <v-btn
          :href="joboffer.applyUrl"
          target="_blank"
          class="d-block d-sm-inline-block"
          >{{ $t('jobs.applyNow') }}</v-btn
        >
      </div>
    </div>

    <div class="content-box mt-8">
      <h2 class="mb-0">Sie haben noch Fragen?</h2>
    </div>
    <div class="content-box alternate job-details__contact-box">
      <v-row>
        <v-col cols="12" md="8">
          <p><strong>Diana Pauli</strong></p>
          <p>Ich helfe Ihnen gerne weiter!</p>

          <div>
            <v-btn class="mr-12 mb-4 mb-sm-0">
              +49 6103 801-456
              <v-icon class="append-icon">mdi-phone</v-icon>
            </v-btn>

            <v-btn>
              Mail
              <v-icon class="append-icon">mdi-email-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            class="round-image ml-0 ml-lg-auto"
            :src="`${apiUrl}/file/public?slug=${slug}&fileName=diana.jpg`"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import basics from '@/components/jobDetails/job-basics.vue';
import contentArea from '@/components/content/content-area.vue';

export default {
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    joboffer: null,
    jobDetails: null
  }),

  watch: {
    $route() {
      this.initialize();
    },

    joboffers() {
      if (!this.joboffer) {
        this.initialize();
      }
    }
  },

  computed: {
    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      slug: 'slug'
    })
  },

  async mounted() {
    if (this.joboffers && this.joboffers.length > 0) {
      await this.initialize();
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      loadJobDetails: 'loadJobDetails',
      loadJobs: 'loadJobs'
    }),

    ...mapMutations({
      setHeroClaim: 'setHeroClaim'
    }),

    async initialize() {
      const jobId = this.$route.params.jobId;
      const job = this.joboffers.find(
        (x) => x.jobofferId.toString() === jobId.toString()
      );

      this.joboffer = job;
      this.setHeroClaim(job.name);

      const response = await this.loadJobDetails({
        slug: 'biotest',
        jobofferId: jobId
      });

      this.jobDetails = response;
    }
  },

  components: {
    basics,
    contentArea
  }
};
</script>
