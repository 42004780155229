<template>
  <div class="fulltext-search">
    <v-menu
      v-model="showResults"
      transition="scale-transition"
      offset-y
      content-class="elevation-0"
      :close-on-content-click="false"
      :nudge-top="5"
    >
      <template v-slot:activator="{ args }">
        <v-text-field
          v-model="searchKeyword"
          v-bind="args"
          outlined
          hide-details
          append-icon="mdi-magnify"
          :placeholder="$t('jobs.fulltextSearch')"
          :filled="filled"
          class="rounded"
          :class="{ bordered: bordered }"
          @keyup="search"
        ></v-text-field>
      </template>
      <div class="fulltext-search__search-results-wrapper">
        <v-sheet class="fulltext-search__search-results">
          <div class="text-right text--gray">
            {{ numSearchResults }} Treffer
          </div>
          <div
            v-for="item in displayedSearchResults"
            :key="`fulltext_result_${item.jobofferId}`"
          >
            <a href="#" @click="navigateToJob(item)">
              {{ localize(item.name) }}
            </a>
          </div>

          <div class="d-flex mt-2">
            <div v-if="hasPreviousItems">
              <v-btn
                small
                plain
                text
                class="px-0"
                :ripple="false"
                @click="goToPrevious"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t('previous') }}
              </v-btn>
            </div>
            <div v-if="hasNextItems" class="d-flex flex-grow-1 justify-end">
              <v-btn
                small
                plain
                text
                class="px-0"
                :ripple="false"
                @click="goToNext"
              >
                {{ $t('next') }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-sheet>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    filled: {
      type: Boolean,
      required: false,
      default: false
    },

    bordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    showResults: false,
    searchKeyword: '',
    pageIndex: 0
  }),

  computed: {
    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      showExternalAdvert: 'showExternalAdvert'
    }),

    hasPreviousItems() {
      return this.numPages > 0 && this.pageIndex > 0;
    },

    hasNextItems() {
      return this.numPages > 0 && this.pageIndex < this.numPages - 1;
    },

    searchResults() {
      const kw = this.searchKeyword.toLowerCase();
      const results = this.joboffers.filter(
        (x) => this.localize(x.name).toLowerCase().indexOf(kw) >= 0
      );

      const sortedResults = results.sort((a, b) =>
        this.localize(a.name).localeCompare(this.localize(b.name))
      );

      return sortedResults;
    },

    numPages() {
      return Math.ceil(this.numSearchResults / this.numResults);
    },

    numResults() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return 5;
        case 'lg':
          return 15;
        case 'xl':
          return 15;
        default:
          return 5;
      }
    },

    numSearchResults() {
      return this.searchResults.length;
    },

    displayedSearchResults() {
      const startItem = this.pageIndex * this.numResults;
      const endItem = startItem + this.numResults;
      return this.searchResults.slice(startItem, endItem);
    }
  },

  methods: {
    clearSearch() {
      this.showResults = false;
      this.searchKeyword = '';
    },

    goToNext() {
      if (this.pageIndex < this.numPages - 1) {
        this.pageIndex++;
      }
    },

    goToPrevious() {
      if (this.pageIndex > 0) {
        this.pageIndex--;
      }
    },

    navigateToJob(joboffer) {
      if (this.showExternalAdvert) {
        window.open(joboffer.advertUrl, '_blank');
      } else {
        this.$router.push({
          name: 'jobDetails',
          params: {
            jobId: joboffer.jobofferId
          }
        });
      }

      this.clearSearch();
    },

    search() {
      if (!this.searchKeyword || this.searchKeyword.length < 3) {
        this.showResults = false;
        return;
      }

      this.showResults = true;
    }
  }
};
</script>
