<template>
  <section class="hero">
    <v-container class="hero__filter-container">
      <div class="d-flex" v-if="hasMobileFilter">
        <fulltext-search filled class="mr-4"></fulltext-search>
        <mobile-filter></mobile-filter>
      </div>
    </v-container>
    <v-container class="hero__claim-wrapper">
      <h1 class="d-block">{{ heroClaim }}</h1>

      <v-row v-if="hasSearchField" class="d-none d-md-flex">
        <v-col cols="12">
          <fulltext-search filled bordered></fulltext-search>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import mobileFilter from '@/components/jobs/mobile-filter.vue';
import fulltextSearch from '@/components/fulltext-search.vue';

export default {
  props: {
    heroClaim: {
      type: String,
      required: false,
      default: ''
    },

    hasSearchField: {
      type: Boolean,
      required: false,
      default: false
    },

    hasMobileFilter: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    mobileFilter,
    fulltextSearch
  }
};
</script>
