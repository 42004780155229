<template>
  <div>
    <component
      v-for="(element, index) in content"
      :key="`content_element_${index}`"
      :is="getElementComponent(element)"
      :data="element"
    ></component>
  </div>
</template>

<script>
import companyBenefits from './company-benefits.vue';
import htmlContent from './html-content.vue';
import companyDepartments from './company-departments.vue';
import expanderContent from './expander-content.vue';
import imageContent from './image-content.vue';

export default {
  name: 'inner-content-area',
  props: {
    content: {
      type: Array,
      required: true
    }
  },

  methods: {
    getElementComponent(contentElement) {
      if (contentElement.componentType === 3) {
        return companyBenefits;
      }

      if (contentElement.componentType === 4) {
        return htmlContent;
      }

      if (contentElement.componentType === 6) {
        return companyDepartments;
      }

      if (contentElement.componentType === 7) {
        return expanderContent;
      }

      if (contentElement.componentType === 9) {
        return imageContent;
      }

      return '';
    }
  }
};
</script>
