<template>
  <section class="jobs-overview">
    <content-area v-if="content" :content="content"></content-area>

    <v-row>
      <v-col cols="12">
        <div class="jobs-overview content-box dense alternate text-left">
          <v-row dense id="jobs-overview-header" class="mb-2">
            <v-col cols="5" class="dense d-flex align-center">
              <strong class="job-overview__item-count"
                >{{ numJobs }} {{ $tc('jobs.joboffers', numJobs) }}</strong
              >
            </v-col>
            <v-col cols="7" class="dense text-right">
              <jobalert-dialog></jobalert-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              v-for="job in joboffers"
              :key="`joboffer_item_${job.jobofferId}`"
            >
              <joboffer-item :joboffer="job"></joboffer-item>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <content-area
      v-if="jobsDefaultContent && jobsDefaultContent.length > 0"
      :content="jobsDefaultContent"
    ></content-area>
  </section>
</template>

<script>
import jobofferItem from '@/components/jobs/joboffer-item.vue';
import contentArea from '@/components/content/content-area.vue';
import jobalertDialog from '@/components/jobalert/signup-dialog.vue';

import { mapMutations, mapGetters, mapState } from 'vuex';

export default {
  created() {
    if (!this.filters || Object.entries(this.filters).length === 0) {
      const routeImage = this.routeImages['jobs'];
      if (routeImage) {
        this.setHeroImage(routeImage);
      }
    }
  },

  computed: {
    ...mapState({
      routeImages: 'routeImages'
    }),

    ...mapState('jobsModule', {
      filters: 'filters'
    }),

    ...mapState('companyModule', {
      jobsDefaultContent: 'jobsDefaultContent'
    }),

    ...mapGetters('jobsModule', {
      joboffers: 'getJoboffers',
      content: 'getContent'
    }),

    numJobs() {
      return this.joboffers.length;
    }
  },

  methods: {
    ...mapMutations({
      setHeroClaim: 'setHeroClaim',
      setHeroImage: 'setHeroImage'
    })
  },

  components: {
    jobofferItem,
    contentArea,
    jobalertDialog
  }
};
</script>
