<template>
  <section>
    <content-area v-if="siteContent" :content="siteContent"></content-area>
    <!-- <v-row>
      <v-col cols="12">
        <div class="content-box content-box__color-secondary text-center">
          <h2>Kommen Sie zu Biotest</h2>
          <p>
            Biotest entwickelt und produziert Medikamente, die lebensbedrohende
            Krankheiten heilen und das Leben von Schwerkranken wesentlich
            erleichtern können. Mehr als 2.000 Mitarbeiter wirken daran Tag für
            Tag mit.
          </p>
          <p>
            An unserem Standort in Dreieich, 20 Kilometer südlich von Frankfurt
            am Main, befinden sich Forschung und Entwicklung, Produktion,
            Marketing und Vertrieb unter einem Dach. Dadurch entstehen
            vielfältige, anspruchsvolle Aufgaben für Mitarbeiter, die bereit
            sind, Verantwortung zu übernehmen. Bei Biotest arbeiten und forschen
            Sie auf höchstem technischem Niveau in einer Gemeinschaft von
            interessanten Menschen.
          </p>
          <p>
            Biotest ist ein erfolgreiches Unternehmen und hat in den letzten
            Jahren seine Geschäftsaktivitäten vervielfacht. Wir wollen auch in
            Zukunft auf Wachstumskurs bleiben. Deshalb streben wir eine
            langfristige Zusammenarbeit mit unseren Mitarbeitern an.
          </p>
          <div class="text-center">
            <v-btn
              x-large
              rounded
              class="px-12 mt-8 text-wrap"
              :to="{ name: 'jobs' }"
            >
              <span class="d-none d-lg-inline">Alle&nbsp;</span> Stellenangebote
              anzeigen
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <content-grid></content-grid>
      </v-col>
    </v-row> -->

    <!-- <div class="content-box text-center">
      <h2>Fachbereiche</h2>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea tak
      </p>

      <v-row>
        <v-col
          v-for="department in departments"
          :key="`department_${department.id}`"
          cols="12"
          md="6"
          lg="4"
        >
          <image-box
            @click="departmentClicked(department)"
            :src="`${apiUrl}/file/public?slug=${slug}&fileName=${department.imageName}`"
            :opacity="0.3"
          >
            <template v-slot:description>{{
              department.name[0].translation
            }}</template>
          </image-box>
        </v-col>
      </v-row>
    </div> -->
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import contentArea from '@/components/content/content-area.vue';
// import imageBox from '@/components/image-box.vue';
// import contentGrid from '@/components/content/content-grid.vue';

export default {
  name: 'HomeView',
  data: () => ({
    departments: [],
    apiUrl: process.env.VUE_APP_API_URL,
    siteContent: null
  }),

  computed: {
    ...mapState({
      routeImages: 'routeImages'
    }),

    ...mapState('companyModule', {
      slug: 'slug',
      jobportalOnly: 'jobportalOnly'
    })
  },

  async created() {
    // if (this.jobportalOnly) {
    //   this.$router.push({ name: 'jobs' });
    //   return;
    // }

    const routeImage = this.routeImages['home'];
    if (routeImage) {
      this.setHeroImage(routeImage);
    }

    this.departments = await this.loadDepartments(this.slug);
  },

  async mounted() {
    const request = {
      slug: this.$route.params.slug,
      siteName: 'home'
    };

    const siteResponse = await this.getSite(request);
    const siteData = await siteResponse.json();
    this.siteContent = siteData.content;
  },

  methods: {
    ...mapActions({
      clearJobsFilter: 'jobsModule/clearFilters',
      loadDepartments: 'companyModule/loadDepartments',
      setJobsFilter: 'jobsModule/setFilter'
    }),

    ...mapActions('sitesModule', {
      getSite: 'getSite'
    }),

    ...mapMutations({
      setHeroImage: 'setHeroImage',
      setJobsContent: 'jobsModule/setContent'
    }),

    departmentClicked(department) {
      this.clearJobsFilter();
      this.setJobsFilter({
        field: 'departmentId',
        value: department.id,
        name: department.name
      });

      // if (department.content) {
      // 	this.setJobsContent(department.content);
      // }

      this.$router.push({ name: 'jobs' });

      // if (department.heroImageName) {
      // 	const vm = this;
      // 	setTimeout(() => {
      // 		vm.setHeroImage(department.heroImageName);
      // 	}, 30);
      // }
    }
  },

  components: {
    // imageBox,
    // contentGrid
    contentArea
  }
};
</script>
