import { render, staticRenderFns } from "./image-box.vue?vue&type=template&id=16886147&scoped=true&"
import script from "./image-box.vue?vue&type=script&lang=js&"
export * from "./image-box.vue?vue&type=script&lang=js&"
import style0 from "./image-box.vue?vue&type=style&index=0&id=16886147&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16886147",
  null
  
)

export default component.exports