<template>
  <div class="content-box text-center">
    <h2 v-if="hasTitle">{{ title }}</h2>
    <p>
      {{ localize(this.data.text) }}
    </p>

    <v-row>
      <v-col
        v-for="department in departments"
        :key="`department_${department.id}`"
        cols="12"
        md="6"
        lg="4"
      >
        <image-box
          @click="departmentClicked(department)"
          :src="`${apiUrl}/file/public?slug=${slug}&fileName=${department.imageName}`"
          :opacity="0.3"
        >
          <template v-slot:description>{{
            localize(department.name)
          }}</template>
        </image-box>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import imageBox from '@/components/image-box.vue';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    departments: [],
    apiUrl: process.env.VUE_APP_API_URL
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  async mounted() {
    this.departments = await this.loadDepartments(this.slug);
  },

  methods: {
    ...mapActions({
      clearJobsFilter: 'jobsModule/clearFilters',
      loadDepartments: 'companyModule/loadDepartments',
      setJobsFilter: 'jobsModule/setFilter'
    }),

    departmentClicked(department) {
      this.clearJobsFilter();
      this.setJobsFilter({
        field: 'departmentId',
        value: department.id,
        name: department.name
      });

      this.$router.push({ name: 'jobs' });
    }
  },

  components: {
    imageBox
  }
};
</script>
